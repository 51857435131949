@use '../global/import' as *;

@include pc {
	.title04 {
		@include incTitle04;
	}
}

@include sp {
	.title04 {
		@include incTitle04(sp);
	}
}
