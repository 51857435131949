@use '../settings/color' as *;
@use '../settings/font' as *;
@use '../settings/path' as *;
@use '../function/function' as *;
@use '../mixin/hover' as *;

@mixin incLinkText($media: pc) {
	@if $media == pc {
		position: relative;
		color: $primary01;
		text-decoration: underline;
		line-height: $lhSmall;
		@include hoverOpacity;
	} @else {
		position: relative;
		color: $primary01;
		text-decoration: underline;
		line-height: $lhSmall;
		@include hoverOpacity;
	}
}

@mixin incLinkTextBlank($media: pc) {
	@if $media == pc {
		&::after {
			display: inline-block;
			margin: 0 4px -2px;
			width: 14px;
			height: 14px;
			background: url(#{$imgPath}/icon-blankRed.svg) no-repeat center / contain;
			content: "";
		}
	} @else {
		&::after {
			display: inline-block;
			margin: 0 vw(4) vw(-2);
			width: vw(14);
			height: vw(14);
			background: url(#{$imgPath}/icon-blankRed.svg) no-repeat center / contain;
			content: "";
		}
	}
}
