@use '../global/import' as *;

@include pc {
	.burger-menu {
		display: none;
	}
}

@include sp {
	.burger-menu {
		display: none;
		border: vw(1) solid $white01;
		background-color: $white01;
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background: $primary01;
		z-index: $burgerMenuZIndex;
		overflow: auto;
		overflow: overlay;
		&__head {
			position: relative;
			height: vw(60);
			background: $white01;
		}
		&__list {
			padding: vw(20) vw(10);
			background-color: $primary01;
		}
		&__item {
			color: $white01;
			border-bottom: vw(1) solid $white01;
			&:first-of-type {
				border-top: vw(1) solid $white01;
			}
		}
		&__link {
			display: block;
			position: relative;
			width: 100%;
			padding: vw(10) 0;
			line-height: $lhSmall;
			@include hoverOpacity;
			&::after {
				position: absolute;
				top: 50%;
				right: vw(10);
				transform: translate(0%, -50%);
				width: vw(17);
				height: vw(10);
				background: url(#{$imgPath}/arrow-link.webp) no-repeat center / contain;
				content: "";
			}
			&--blank {
				&::after {
					content: none;
				}
			}
		}
		&__text {
			position: relative;
			&::after {
				display: inline-block;
				position: absolute;
				right: vw(-5);
				bottom: vw(3);
				transform: translate(100%, 0);
				width: vw(14);
				height: vw(14);
				background: url(#{$imgPath}/icon-blankWhite.svg) no-repeat center / contain;
				transition: $transitionShort;
				content: "";
			}
		}
	}
}
