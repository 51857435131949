@use '../global/import' as *;

@include pc {
	.btn-link01 {
		display: grid;
		place-content: center;
		position: relative;
		width: 355px;
		height: 70px;
		margin: 50px auto 0;
		box-shadow: 0 4px $shadowPrimary01;
		border-radius: 5px;
		background-color: $primary01;
		color: $white01;
		font-size: 17px;
		font-weight: $fwMedium;
		text-align: center;
		@include hoverOpacity;
		&:hover {
			box-shadow: none;
			transform: translateY(4px);
		}
		&::before {
			position: absolute;
			top: 50%;
			right: 10px;
			transform: translate(0, -50%);
			width: 33px;
			height: 10px;
			background: url(#{$imgPath}/arrow-next.webp) no-repeat center / contain;
			content: '';
		}
	}
}

@include sp {
	.btn-link01 {
		display: grid;
		place-content: center;
		position: relative;
		width: 100%;
		height: vw(70);
		margin-top: vw(30);
		box-shadow: 0 vw(4) $shadowPrimary01;
		border-radius: vw(5);
		background-color: $primary01;
		color: $white01;
		font-weight: $fwMedium;
		text-align: center;
		@include hoverOpacity;
		&:hover {
			box-shadow: none;
			transform: translateY(vw(4))
		}
		&::before {
			position: absolute;
			top: 50%;
			right: vw(10);
			transform: translate(0, -50%);
			width: vw(33);
			height: vw(10);
			background: url(#{$imgPath}/arrow-next.webp) no-repeat center / contain;
			content: '';
		}
	}
}
