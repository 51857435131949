@use '../global/import' as *;

@include pc {
	.toc {
		$this: &;
		width: 650px;
		margin: 60px auto 0;
		&__term {
			position: relative;
			background-color: $primary03;
			padding: 20px 0;
			border-radius: 5px 5px 0 0;
			font-size: 20px;
			font-weight: $fwMedium;
			line-height: $lhFlat;
			text-align: center;
			cursor: pointer;
			@include hoverOpacity;
			&::before, &::after {
				content: "";
				display: inline-block;
				background-color: $primary01;
				margin: auto;
				position: absolute;
				top: 0;
				bottom: 0;
				transition: height $transitionShort;
			}
			&:before {
				width: 15px;
				height: 3px;
				right: 19px;
			}
			&:after {
				width: 3px;
				height: 15px;
				right: 25px;
			}
		}
		&__desc {
			display: none;
			padding: 25px;
			border: 1px solid $gray04;
			border-top: none;
			border-radius: 0 0 5px 5px;
		}
		&__list {
			display: grid;
			row-gap: 8px;
			counter-reset: number 0;
			&--lower {
				padding-left: 32px;
			}
		}
		&__item {
			&--lv2 {
				#{$this}__link {
					padding-left: 30px;
					&::before {
						position: absolute;
						top: 0;
						left: 0;
						display: inline-block;
						color: $primary01;
						font-weight: $fwBold;
						counter-increment: number;
						content: "0" counter(number);
					}
				}
			}
			&--lv3 {
				#{$this}__link {
					padding-left: 45px;
					&::before {
						position: absolute;
						top: 0;
						left: 0;
						display: inline-block;
						color: $primary01;
						font-weight: $fwBold;
						counter-increment: number;
						content: "02-" counter(number);
					}
				}
			}
		}
		&__link {
			display: inline-block;
			position: relative;
			@include hoverOpacity();
		}
		// js
		&.is-active {
			#{$this}__term::after {
				height: 0;
			}
		}
	}
}

@include sp {
	.toc {
		$this: &;
		margin-top: vw(40);
		&__term {
			position: relative;
			background-color: $primary03;
			padding: vw(20) 0;
			border-radius: vw(5) vw(5) 0 0;
			font-size: vw(20);
			font-weight: $fwMedium;
			line-height: $lhFlat;
			text-align: center;
			cursor: pointer;
			@include hoverOpacity;
			&::before, &::after {
				content: "";
				display: inline-block;
				background-color: $primary01;
				margin: auto;
				position: absolute;
				top: 0;
				bottom: 0;
				transition: height $transitionShort;
			}
			&:before {
				width: vw(15);
				height: vw(3);
				right: vw(19);
			}
			&:after {
				width: vw(3);
				height: vw(15);
				right: vw(25);
			}
		}
		&__desc {
			display: none;
			padding: vw(20);
			border: vw(1) solid $gray04;
			border-top: none;
			border-radius: 0 0 vw(5) vw(5);
		}
		&__list {
			display: grid;
			row-gap: vw(8);
			counter-reset: number 0;
			&--lower {
				padding-left: vw(32);
			}
		}
		&__item {
			&--lv2 {
				#{$this}__link {
					padding-left: vw(30);
					&::before {
						position: absolute;
						top: 0;
						left: 0;
						display: inline-block;
						color: $primary01;
						font-weight: $fwBold;
						counter-increment: number;
						content: "0" counter(number);
					}
				}
			}
			&--lv3 {
				#{$this}__link {
					padding-left: vw(45);
					&::before {
						position: absolute;
						top: 0;
						left: 0;
						display: inline-block;
						color: $primary01;
						font-weight: $fwBold;
						counter-increment: number;
						content: "02-" counter(number);
					}
				}
			}
		}
		&__link {
			display: inline-block;
			position: relative;
			@include hoverOpacity();
		}
		// js
		&.is-active {
			#{$this}__term::after {
				height: 0;
			}
		}
	}
}
