@use '../global/import' as *;

.m0:not(#_) {
	margin: 0;
}
.m0a:not(#_) {
	margin: 0 auto;
}
.mt0:not(#_) {
	margin-top: 0;
}
.mb0:not(#_) {
	margin-bottom: 0;
}
.ml0:not(#_) {
	margin-left: 0;
}
.mr0:not(#_) {
	margin-right: 0;
}

@include pc {
	@for $i from 0 through 20 {
		.mt#{$i * 5}-pc:not(#_) {
			margin-top: 5px * $i;
		}
		.ml#{$i * 5}-pc:not(#_) {
			margin-left: 5px * $i;
		}
		.mr#{$i * 5}-pc:not(#_) {
			margin-right: 5px * $i;
		}
	}
	.m0-pc:not(#_) {
		margin: 0;
	}
	.m0a-pc:not(#_) {
		margin: 0 auto;
	}
	.mt0-pc:not(#_) {
		margin-top: 0;
	}
	.mb0-pc:not(#_) {
		margin-bottom: 0;
	}
	.ml0-pc:not(#_) {
		margin-left: 0;
	}
	.mr0-pc:not(#_) {
		margin-right: 0;
	}
}

@include sp {
	@for $i from 0 through 20 {
		.mt#{$i * 5}-sp:not(#_) {
			margin-top: vw(5) * $i;
		}
		.ml#{$i * 5}-sp:not(#_) {
			margin-left: vw(5) * $i;
		}
		.mr#{$i * 5}-sp:not(#_) {
			margin-right: vw(5) * $i;
		}
	}
	.m0-sp:not(#_) {
		margin: 0;
	}
	.m0a-sp:not(#_) {
		margin: 0 auto;
	}
	.mt0-sp:not(#_) {
		margin-top: 0;
	}
	.mb0-sp:not(#_) {
		margin-bottom: 0;
	}
	.ml0-sp:not(#_) {
		margin-left: 0;
	}
	.mr0-sp:not(#_) {
		margin-right: 0;
	}
}
