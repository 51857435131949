@use '../global/import' as *;

.d-none:not(#_) {
	display: none;
}
.d-block:not(#_) {
	display: block;
}
.d-inline-block:not(#_) {
	display: inline-block;
}
.d-none-before:not(#_) {
	&::before {
		content: none;
	}
}
.d-none-after:not(#_) {
	&::after {
		content: none;
	}
}

@include pc {
	.d-block-pc:not(#_) {
		display: block;
	}
	.d-inline-block-pc:not(#_) {
		display: inline-block;
	}
	.d-none-pc:not(#_) {
		display: none;
	}
}

@include sp {
	.d-block-sp:not(#_) {
		display: block;
	}
	.d-inline-block-sp:not(#_) {
		display: inline-block;
	}
	.d-none-sp:not(#_) {
		display: none;
	}
}
