@use '../global/import' as *;

@include pc {
	.gnav-menu {
		$this: &;
		display: flex;
		column-gap: 80px;
		position: absolute;
		bottom: -10px;
		left: 0;
		transform: translate(0%, 100%);
		width: 780px;
		background-color: $white01;
		padding: 35px 25px;
		border: $primary01 2px solid;
		visibility: hidden;
		opacity: 0;
		z-index: $gnavZIndex;
		&__list {
			position: relative;
			display: grid;
			row-gap: 22px;
			align-self: start;
			padding-top: 10px;
			&:first-of-type {
				&::before {
					position: absolute;
					top: 0;
					left: 270px;
					width: 2px;
					height: 100%;
					background-color: $gray02;
					content: "";
				}
			}
		}
		&__item {
			position: relative;
			width: 230px;
			font-size: 17px;
			font-weight: $fwBold;
			align-self: start;
		}
		&__link {
			display: block;
			transition: $transitionShort;
			&::after {
				position: absolute;
				top: 50%;
				right: 0;
				transform: translate(0%, -50%);
				width: 20px;
				height: 9px;
				background: url(#{$imgPath}/arrow-nav.webp) no-repeat center / contain;
				content: "";
			}
			&:hover {
				color: $primary01;
				&::after {
					background: url(#{$imgPath}/arrow-navRed.webp) no-repeat center / contain;
				}
			}
		}
		&__other-list {
			padding: 15px 0 0 40px;
		}
		&__other-item {
			position: relative;
			font-size: 17px;
			font-weight: $fwBold;
			transition: $transitionShort;
			&--full {
				grid-column: 2;
				flex-basis: 100%;
			}
		}
		&__other-link {
			position: relative;
			transition: $transitionShort;
			&::after {
				position: absolute;
				top: 50%;
				right: -5px;
				transform: translate(100%, -50%);
				width: 14px;
				height: 14px;
				background: url(#{$imgPath}/icon-blank.svg) no-repeat center / contain;
				transition: $transitionShort;
				content: "";
			}
			&:hover {
				color: $primary01;
				&::after {
					background: url(#{$imgPath}/icon-blankRed.svg) no-repeat center / contain;
				}
			}
		}
	}
}

@include sp {
	.gnav-menu {
		display: none;
	}
}
