@use '../global/import' as *;

@include pc {
	.post-date {
		display: block;
		line-height: $lhFlat;
		color: $gray04;
		font-size: 12px;
	}
}

@include sp {
	.post-date {
		display: block;
		line-height: $lhFlat;
		color: $gray04;
		font-size: vw(12);
	}
}
