@use '../settings/color' as *;
@use '../settings/font' as *;
@use '../function/function' as *;

@mixin incTitle03($media: pc) {
	@if $media == pc {
		position: relative;
		border-bottom: 5px solid $primary02;
		margin-top: 90px;
		padding: 0 0 8px;
		font-weight: $fwBold;
		font-size: 28px;
		line-height: $lhSmall;
		&::before {
			position: absolute;
			bottom: 0;
			left: 0;
			width: 60px;
			height: 6px;
			background-color: $primary01;
			content: "";
			transform: translateY(100%);
		}
		& + *:not(#_) {
			margin-top: 30px;
		}
	} @else {
		position: relative;
		margin-top: vw(60);
		padding: 0 0 vw(8);
		border-bottom: vw(5) solid $primary02;
		font-weight: $fwBold;
		font-size: vw(24);
		line-height: $lhSmall;
		&::before {
			position: absolute;
			bottom: 0;
			left: 0;
			width: vw(50);
			height: vw(5);
			background-color: $primary01;
			content: "";
			transform: translateY(100%);
		}
		& + *:not(#_) {
			margin-top: vw(30);
		}
	}
}
