@use '../settings/color' as *;
@use '../settings/font' as *;
@use '../function/function' as *;

@mixin incTitle05($media: pc) {
	@if $media == pc {
		margin-top: 30px;
		padding-bottom: 5px;
		border-bottom: 2px solid $primary02;
		font-size: 20px;
		font-weight: $fwBold;
		line-height: $lhSmall;
		& + *:not(#_) {
			margin-top: 20px;
		}
	} @else {
		margin-top: vw(25);
		padding-bottom: vw(5);
		border-bottom: vw(2) solid $primary02;
		font-size: vw(18);
		font-weight: $fwBold;
		line-height: $lhSmall;
		& + *:not(#_) {
			margin-top: vw(20);
		}
	}
}
