@use '../global/import' as *;

@include pc {
	.btn-web {
		$this: &;
		display: grid;
		align-items: center;
		position: relative;
		padding: 0 0 0 110px;
		width: 327px;
		height: 73px;
		font-size: 20px;
		background-color: $primary01;
		border-radius: 5px;
		box-shadow: 0 4px $shadowPrimary01;
		color: $white01;
		font-weight: $fwBold;
		@include hoverOpacity;
		&:before {
			position: absolute;
			top: 50%;
			left: 17px;
			transform: translateY(-50%);
			width: 53px;
			height: 41px;
			background: url(#{$imgPath}/icon-webWhite.svg) no-repeat top center / cover;
			content: "";
		}
		&:hover {
			transform: translateY(4px);
			box-shadow: none;
		}
		&__text {
			display: inline-block;
			width: fit-content;
			text-align: center;
		}
		&--float {
			width: 310px;
			padding: 0 0 0 100px;
			height: 70px;
			&::before {
				width: 50px;
				height: 38px;
			}
		}
		&--header {
			width: 200px;
			padding: 0 0 0 65px;
			height: 45px;
			box-shadow: 0 3px $shadowPrimary01;
			#{$this}__text {
				font-size: 13px;
			}
			&:hover {
				transform: translateY(3px);
			}
			&::before {
				width: 32px;
				height: 24px;
			}
		}
		&--lower {
			padding: 0 0 0 130px;
			width: 420px;
			height: 77px;
			font-size: 24px;
			background-color: $green01;
			box-shadow: 0 6px $shadowGreen01;
			&:before {
				left: 37px;
				width: 53px;
				height: 41px;
				content: "";
			}
		}
	}
}

@include sp {
	.btn-web {
		$this: &;
		display: grid;
		align-items: center;
		position: relative;
		padding: 0 0 0 vw(82);
		width: min(vw(313), 100%);
		height: vw(73);
		margin: 0 auto;
		border: vw(2) solid $primary01;
		font-size: vw(23);
		background-color: $white01;
		border-radius: vw(5);
		box-shadow: 0 vw(2) $shadowPrimary01;
		color: $primary01;
		font-weight: $fwBold;
		@include hoverOpacity;
		&:before {
			position: absolute;
			top: 50%;
			left: vw(30);
			transform: translateY(-50%);
			width: vw(41);
			height: vw(31);
			background: url(#{$imgPath}/icon-webRed.svg) no-repeat top center / cover;
			content: "";
		}
		&:hover {
			transform: translateY(vw(2));
			box-shadow: none;
		}
		&__text {
			display: inline-block;
			width: fit-content;
			text-align: center;
		}
		&--float {
			width: vw(170);
			padding: 0 0 0 vw(34);
			height: vw(50);
			#{$this}__text {
				font-size: vw(15);
			}
			&::before {
				left: vw(5);
				width: vw(24);
				height: vw(20);
			}
		}
		&--lower {
			margin-top: vw(25);
			padding: 0 0 0 vw(105);
			width: min(vw(355), 100%);
			height: vw(70);
			font-size: vw(23);
			background-color: $green01;
			box-shadow: 0 vw(4) $shadowGreen01;
			border-color: $green01;
			color: $white01;
			&:before {
				left: vw(50);
				width: vw(41);
				height: vw(31);
				background: url(#{$imgPath}/icon-webWhite.svg) no-repeat top center / cover;
				content: "";
			}
			&:hover {
				transform: translateY(vw(4));
			}
		}
	}
}
