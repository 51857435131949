@use '../global/import' as *;

@include pc {
	.pagination {
		display: flex;
		align-items: center;
		gap: 10px;
		width: fit-content;
		margin: 60px auto 0;
		&__number {
			display: grid;
			place-content: center;
			width: 38px;
			height: 38px;
			font-size: 18px;
			font-weight: $fwMedium;
			color: $primary01;
			border: 2px solid $primary01;
			border-radius: 50%;
			@include hoverOpacity();
			&--current {
				background-color: $primary01;
				color: $white01;
			}
			&--dots {
				width: fit-content;
				height: fit-content;
				border: none;
				color: $gray04;
			}
		}
		&__btn {
			position: relative;
			display: inline-block;
			width: 13px;
			height: 13px;
			&--prev {
				margin-right: 12px;
			}
			&--next {
				margin-left: 12px;
			}
		}
		&__btn-arrow {
			$this: &;
			position: absolute;
			top: 50%;
			left: 0;
			transform: translateY(-50%);
			width: 100%;
			height: 100%;
			border-top: 2px solid $primary01;
			border-right: 2px solid $primary01;
			@include hoverOpacity;
			&--prev {
				transform: translateY(-50%) rotate(-135deg);
			}
			&--next {
				transform: translateY(-50%) rotate(45deg);
			}
		}
	}
}

@include sp {
	.pagination {
		display: flex;
		gap: vw(10);
		align-items: center;
		width: fit-content;
		margin: vw(30) auto 0;
		&__number {
			display: grid;
			place-content: center;
			width: vw(34);
			height: vw(34);
			font-size: vw(16);
			font-weight: $fwMedium;
			color: $primary01;
			border: vw(2) solid $primary01;
			border-radius: 50%;
			@include hoverOpacity();
			&--current {
				background-color: $primary01;
				color: $white01;
			}
			&--dots {
				width: fit-content;
				height: fit-content;
				border: none;
				color: $gray04;
				font-weight: $fwBold;
			}
		}
		&__btn {
			position: relative;
			display: inline-block;
			width: vw(12);
			height: vw(12);
			&--prev {
				margin-right: vw(5);
			}
			&--next {
				margin-left: vw(5);
			}
		}
		&__btn-arrow {
			$this: &;
			position: absolute;
			top: 50%;
			left: 0;
			transform: translateY(-50%);
			width: 100%;
			height: 100%;
			border-top: vw(2) solid $primary01;
			border-right: vw(2) solid $primary01;
			@include hoverOpacity;
			&--prev {
				transform: translateY(-50%) rotate(-135deg);
			}
			&--next {
				transform: translateY(-50%) rotate(45deg);
			}
		}
	}
}
