@use '../global/import' as *;

@include pc {
	.news-item {
		$this: &;
		&:first-of-type {
			#{$this}__link {
				padding-top: 0;
			}
		}
		&:last-of-type {
			#{$this}__link {
				padding-bottom: 0;
			}
		}
		&:not(:last-of-type) {
			border-bottom: $gray02 1px dashed;
		}
		&__link {
			display: flex;
			gap: 30px;
			padding: 25px 0;
			font-weight: $fwBold;
			@include hoverOpacity;
		}
		&__date {
			display: block;
			color: $primary01;
		}
		&__title {
			flex: 1;
		}
	}
}

@include sp {
	.news-item {
		$this: &;
		&:first-of-type {
			#{$this}__link {
				padding-top: 0;
			}
		}
		&:last-of-type {
			#{$this}__link {
				padding-bottom: 0;
			}
		}
		&:not(:last-of-type) {
			border-bottom: $gray02 1px dashed;
		}
		&__link {
			display: grid;
			row-gap: vw(10);
			padding: vw(15) 0;
			font-weight: $fwBold;
			@include hoverOpacity;
		}
		&__date {
			display: block;
			color: $primary01;
		}
	}
}
