@use '../global/import' as *;

@include pc {
	.wysiwyg {
		& > *:first-child {
			margin-top: 0;
		}
		/*
		********** 通常テキスト **********
		*/
		p {
			margin-top: 20px;
		}
		/*
		********** リンク **********
		*/
		a {
			@include incLinkText;
			&[target="_blank"]{
				@include incLinkTextBlank();
			}
		}
		/*
		********** リスト **********
		*/
		ul {
			@include incList01();
			li {
				@include incListItem01();
				* {
					line-height: $lhFlat;
				}
			}
		}
		ol {
			@include incListOrder01;
			& > li {
				@include incListOrderItem01;
				* {
					line-height: $lhFlat;
				}
			}
		}
	}
}

@include sp {
	.wysiwyg {
		& > *:first-child {
			margin-top: 0;
		}
		/*
		********** 通常テキスト **********
		*/
		p {
			margin-top: vw(20);
		}
		/*
		********** リンク **********
		*/
		a {
			@include incLinkText(sp);
			&[target="_blank"]{
				@include incLinkTextBlank();
			}
		}
		/*
		********** リスト **********
		*/
		ul {
			@include incList01(sp);
			li {
				@include incListItem01(sp);
				* {
					line-height: $lhFlat;
				}
			}
		}
		ol {
			@include incListOrder01(sp);
			& > li {
				@include incListOrderItem01(sp);
				* {
					line-height: $lhFlat;
				}
			}
		}
	}
}
