@use '../global/import' as *;

@include pc {
	.footer {
		padding: 50px 0;
		background-color: $black01;
		color: $white01;
		&__inner {
			display: grid;
			grid-template-columns: 440px 1fr;
			justify-content: space-between;
			align-items: center;
		}
	}
}

@include sp {
	.footer {
		padding: vw(40) 0;
		background-color: $black01;
		color: $white01;
		&__inner {
			display: flex;
			flex-direction: column-reverse;
			row-gap: vw(30);
		}
	}
}
