@use '../settings/color' as *;
@use '../settings/font' as *;
@use '../function/function' as *;

@mixin incTitle04($media: pc) {
	@if $media == pc {
		position: relative;
		margin-top: 60px;
		padding-left: 15px;
		font-size: 22px;
		font-weight: $fwBold;
		line-height: $lhSmall;
		&::before {
			position: absolute;
			top: 1px;
			left: 0;
			width: 4px;
			height: 26px;
			border-radius: 4px;
			background-color: $primary01;
			content: "";
		}
		& + *:not(#_) {
			margin-top: 20px;
		}
	} @else {
		position: relative;
		margin-top: vw(40);
		padding-left: vw(12);
		font-size: vw(20);
		font-weight: $fwBold;
		line-height: $lhSmall;
		&::before {
			position: absolute;
			top: vw(1);
			left: 0;
			width: vw(4);
			height: vw(26);
			border-radius: vw(4);
			background-color: $primary01;
			content: "";
		}
		& + *:not(#_) {
			margin-top: vw(20);
		}
	}
}
