@use '../global/import' as *;

@include pc {
	.title06 {
		padding: 15px;
		border-top: 3px solid $primary01;
		border-bottom: 1px solid $primary01;
		background-color: $primary03;
		font-size: 28px;
		font-weight: $fwBold;
		line-height: $lhSmall;
		& + *:not(#_) {
			margin-top: 30px;
		}
	}
}

@include sp {
	.title06 {
		padding: vw(12);
		border-top: vw(3) solid $primary01;
		border-bottom: vw(1) solid $primary01;
		background-color: $primary03;
		font-size: vw(20);
		font-weight: $fwBold;
		& + *:not(#_) {
			margin-top: vw(15);
		}
	}
}
