@use '../global/import' as *;

@include pc {
	.sv {
		$this: &;
		display: grid;
		row-gap: 30px;
		margin-top: 90px;
		padding: 25px 30px 30px 30px;
		border: 1px solid $primary01;
		&__head {
			border-bottom: 2px solid $primary01;
			font-size: 20px;
			font-weight: $fwBold;
		}
		&__profile {
			display: flex;
			column-gap: 30px;
		}
		&__picture {
			width: 170px;
			height: 225px;
		}
		&__img {
			width: 100%;
			height:100%;
			object-fit: cover;
		}
		&__lists {
			display: grid;
			row-gap: 15px;
			flex: 1;
			margin-top: 12px;
		}
		&__list {
			display: flex;
			column-gap: 18px;
			font-size: 18px;
			&--deco {
				color: $primary01;
				#{$this}__phonetic {
					padding-bottom: 2px;
					font-size: 14px;
				}
				#{$this}__term {
					width: fit-content;
				}
			}
		}
		&__term {
			width: 75px;
			font-weight: $fwBold;
		}
		&__desc {
			flex: 1;
		}
		&__link {
			display: flex;
			align-items: flex-end;
			width: fit-content;
			column-gap: 8px;
			font-weight: $fwBold;
			@include hoverOpacity();
		}
		&__phonetic {
			position: relative;
			transition: $transitionShort;
			&::after {
				position: absolute;
				top: 50%;
				right: -6px;
				transform: translate(100%, -50%);
				width: 16px;
				height: 16px;
				background: url(#{$imgPath}/icon-blank.svg) no-repeat center / contain;
				content: "";
			}
		}
		&__comment {
			margin-top: 30px;
		}
	}
}

@include sp {
	.sv {
		$this: &;
		display: grid;
		row-gap: vw(15);
		margin-top: vw(60);
		padding: vw(10) vw(15) vw(15) vw(15);
		border: vw(1) solid $primary01;
		&__head {
			border-bottom: vw(2) solid $primary01;
			font-size: vw(20);
			font-weight: $fwBold;
		}
		&__body {
			display: grid;
			row-gap: vw(15);
		}
		&__profile {
			display: grid;
			row-gap: vw(10);
		}
		&__picture {
			display: block;
			margin: 0 auto;
			width: vw(177);
			height: vw(235);
		}
		&__img {
			width: 100%;
			height:100%;
			object-fit: cover;
		}
		&__lists {
			display: grid;
			row-gap: vw(10);
		}
		&__list {
			&--deco {
				line-height: $lhFlat;
				color: $primary01;
				font-size: vw(18);
				#{$this}__phonetic {
					font-size: vw(14);
				}
			}
		}
		&__term {
			font-weight: $fwBold;
		}
		&__link {
			display: flex;
			column-gap: vw(8);
			width: fit-content;
			align-items: flex-end;
			font-weight: $fwBold;
			@include hoverOpacity();
		}
		&__phonetic {
			position: relative;
			transition: $transitionShort;
			padding-bottom: vw(2);
			&::after {
				position: absolute;
				top: 50%;
				right: vw(-6);
				transform: translate(100%, -50%);
				width: vw(16);
				height: vw(16);
				background: url(#{$imgPath}/icon-blank.svg) no-repeat center / contain;
				content: "";
			}
		}
	}
}
