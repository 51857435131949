@use '../global/import' as *;

@include pc {
	.title05 {
		@include incTitle05;
	}
}

@include sp {
	.title05 {
		@include incTitle05(sp);
	}
}
