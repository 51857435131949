@use '../global/import' as *;

@include pc {
	.container {
		margin-top: 30px;
		& > *:first-child {
			margin-top: 0;
		}
	}
}

@include sp {
	.container {
		margin-top: vw(30);
		& > *:first-child {
			margin-top: 0;
		}
	}
}
