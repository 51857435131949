@use '../global/import' as *;

@include pc {
	.box-tel {
		margin-top: 30px;
		padding: 30px 20px;
		background-color: $beige01;
		&__head {
			padding-bottom: 10px;
			border-bottom: 2px solid $primary01;
			font-weight: $fwMedium;
			font-size: 22px;
			text-align: center;
		}
		&__body {
			margin-top: 20px;
		}
	}
}

@include sp {
	.box-tel {
		margin-top: vw(30);
		padding: vw(30) vw(20);
		background-color: $beige01;
		&__head {
			padding-bottom: vw(10);
			border-bottom: vw(2) solid $primary01;
			font-weight: $fwMedium;
			font-size: vw(20);
			text-align: center;
		}
		&__body {
			margin-top: vw(20);
		}
	}
}
