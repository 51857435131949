@use '../settings/width' as *;

// media query
@mixin pc {
	@media screen and (min-width: $mediaPc) {
		@content;
	}
}

@mixin sp {
	@media screen and (max-width: $mediaSp) {
		@content;
	}
}
