@use '../global/import' as *;

@include pc {
	.list-order03 {
		counter-reset: number 0;
		display: grid;
		row-gap: 15px;
		margin-top: 30px;
		&__item {
			position: relative;
			&::before {
				content: counter(number, upper-roman) ".";
				counter-increment: number;
				font-weight: $fwBold;
			}
		}
		&__title {
			font-weight: $fwBold;
		}
		&__link {
			margin-top: 5px;
		}
	}
}

@include sp {
	.list-order03 {
		counter-reset: number 0;
		display: grid;
		row-gap: vw(15);
		margin-top: vw(30);
		&__item {
			position: relative;
			&::before {
				content: counter(number, upper-roman) ".";
				counter-increment: number;
				font-weight: $fwBold;
			}
		}
		&__title {
			font-weight: $fwBold;
		}
		&__link {
			margin-top: vw(5);
		}
	}
}
