@use '../global/import' as *;

// color
.color-primary:not(#_) {
	color: $primary01;
}

// marker
.marker-yellow:not(#_) {
	background: linear-gradient(transparent 70%, $marker01 70%);
}

// font-weight
.fw-normal:not(#_) {
	font-weight: $fwNormal;
}
.fw-medium:not(#_) {
	font-weight: $fwMedium;
}
.fw-bold:not(#_) {
	font-weight: $fwBold;
}

// text-align
.align-l:not(#_) {
	text-align: left;
}
.align-c:not(#_) {
	text-align: center;
}
.align-r:not(#_) {
	text-align: right;
}

// line-height
.lh-normal {
	line-height: $lhNormal;
}
.lh-small {
	line-height: $lhSmall;
}
.lh-flat {
	line-height: $lhFlat;
}

@include pc {
	// text-align
	.align-l-pc:not(#_) {
		text-align: left;
	}
	.align-c-pc:not(#_) {
		text-align: center;
	}
	.align-r-pc:not(#_) {
		text-align: right;
	}
	// font-size
	.font-small:not(#_) {
		font-size: 11px;
	}
	// line-height
	.lh-normal-pc {
		line-height: $lhNormal;
	}
	.lh-small-pc {
		line-height: $lhSmall;
	}
	.lh-flat-pc {
		line-height: $lhFlat;
	}
}

@include sp {
	// text-align
	.align-l-sp:not(#_) {
		text-align: left;
	}
	.align-c-sp:not(#_) {
		text-align: center;
	}
	.align-r-sp:not(#_) {
		text-align: right;
	}
	// font-size
	.font-small:not(#_) {
		font-size: vw(11);
	}
	// line-height
	.lh-normal-sp {
		line-height: $lhNormal;
	}
	.lh-small-sp {
		line-height: $lhSmall;
	}
	.lh-flat-sp {
		line-height: $lhFlat;
	}
}
