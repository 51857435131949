@use '../global/import' as *;

@include pc {
	.list-order01 {
		@include incListOrder01;
		&__item {
			@include incListOrderItem01;
		}
	}
}

@include sp {
	.list-order01 {
		@include incListOrder01(sp);
		&__item {
			@include incListOrderItem01(sp);
		}
	}
}
