@use '../global/import' as *;

@include pc {
	.gnav {
		$this: &;
		padding: 0 0 10px;
		&__list {
			display: flex;
			align-items: center;
			border-left: $gray02 2px dotted;
		}
		&__item {
			position: relative;
			flex: 1;
			border-right: $gray02 2px dotted;
			&--parent {
				position: relative;
				&::after {
					position: absolute;
					bottom: 0;
					left: 0;
					transform: translateY(100%);
					background-color: transparent;
					width: 780px;
					height: 10px;
					content: "";
				}
				#{$this}__text {
					position: relative;
					cursor: pointer;
					&::before {
						position: absolute;
						top: 50%;
						right: 20px;
						transform: translate(0%, -50%);
						width: 16px;
						height: 9px;
						background: url(#{$imgPath}/arrow-open.svg) no-repeat center / contain;
						content: "";
					}
					&:hover {
						&::before {
							background: url(#{$imgPath}/arrow-openRed.svg) no-repeat center / contain;
						}
					}
				}
			}
			&:hover {
				.gnav-menu {
					visibility: visible;
					opacity: 1;
					transition: $transitionShort;
				}
			}
		}
		&__text {
			display: block;
			padding: 5px 0;
			font-weight: $fwBold;
			font-size: 17px;
			text-align: center;
			transition: color $transitionShort;
			&:hover {
				color: $primary01;
			}
		}
	}
}

@include sp {
	.gnav {
		display: none;
	}
}
