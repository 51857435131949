@use '../global/import' as *;

@include pc {
	.inner {
		width: 1000px;
		margin: 0 auto;
		&--lg {
			width: 1100px;
		}
		&--sm {
			width: 800px;
		}
	}
}

@include sp {
	.inner {
		margin: 0 vw(10);
		&--sm {
			margin: 0 vw(17.5);
		}
	}
}
