@use '../global/import' as *;

@include pc {
	.overview {
		width: 650px;
		margin: 60px auto 0;
		padding: 30px;
		border: 1px solid $primary01;
		&__term {
			padding-bottom: 20px;
			border-bottom: 1px solid $primary01;
			line-height: $lhFlat;
			font-weight: $fwMedium;
			font-size: 20px;
			text-align: center;
		}
		&__desc {
			position: relative;
			padding-left: 28px;
			&::before {
				position: absolute;
				top: 6px;
				left: 0;
				width: 16px;
				height: 16px;
				background: url(#{$imgPath}/columns/icon-checkBox.svg) no-repeat center / cover;
				content: "";
			}
			&:first-of-type {
				margin-top: 25px;
			}
			&:not(:first-of-type) {
				margin-top: 18px;
			}
		}
	}
}

@include sp {
	.overview {
		margin-top: vw(30);
		padding: vw(15);
		border: vw(1) solid $primary01;
		&__term {
			padding-bottom: vw(20);
			border-bottom: vw(1) solid $primary01;
			line-height: $lhFlat;
			font-weight: $fwMedium;
			font-size: vw(20);
			text-align: center;
		}
		&__desc {
			position: relative;
			padding-left: vw(30);
			&::before {
				position: absolute;
				top: vw(6);
				left: 0;
				width: vw(16);
				height: vw(16);
				background: url(#{$imgPath}/columns/icon-checkBox.svg) no-repeat center / cover;
				content: "";
			}
			&:first-of-type {
				margin-top: vw(20);
			}
			&:not(:first-of-type) {
				margin-top: vw(15);
			}
		}
	}
}
