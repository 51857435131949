@use '../global/import' as *;

@include pc {
	.columns-list {
		display: grid;
		row-gap: 30px;
	}
}

@include sp {
	.columns-list {
		display: grid;
		row-gap: vw(20);
	}
}
