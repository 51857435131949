@use '../global/import' as *;

@include pc {
	.btn-link02 {
		display: grid;
		place-content: center;
		position: relative;
		width: 500px;
		height: 70px;
		margin: 0 auto;
		border: 2px solid $primary01;
		border-radius: 5px;
		box-shadow: 0 4px $primary01;
		background-color: $white01;
		color: $primary01;
		font-size: 17px;
		font-weight: $fwMedium;
		text-align: center;
		line-height: $lhSmall;
		@include hoverOpacity;
		&:hover {
			box-shadow: none;
			transform: translateY(4px);
		}
		&::before {
			position: absolute;
			top: 50%;
			right: 10px;
			transform: translate(0, -50%);
			width: 33px;
			height: 10px;
			background: url(#{$imgPath}/arrow-nextRed.webp) no-repeat center / contain;
			content: '';
		}
	}
}

@include sp {
	.btn-link02 {
		display: grid;
		place-content: center;
		position: relative;
		width: 100%;
		height: vw(70);
		margin: 0 auto;
		border: vw(2) solid $primary01;
		border-radius: vw(5);
		box-shadow: 0 vw(4) $primary01;
		background-color: $white01;
		color: $primary01;
		font-size: vw(16);
		font-weight: $fwMedium;
		text-align: center;
		line-height: $lhSmall;
		@include hoverOpacity;
		&:hover {
			box-shadow: none;
			transform: translateY(vw(4));
		}
		&::before {
			position: absolute;
			top: 50%;
			right: vw(10);
			transform: translate(0, -50%);
			width: vw(33);
			height: vw(10);
			background: url(#{$imgPath}/arrow-nextRed.webp) no-repeat center / contain;
			content: '';
		}
	}
}
