@use '../global/import' as *;

@include pc {
	.burger-wrap {
		display: none;
	}
}

@include sp {
	.burger {
		$this: &;
		display: grid;
		row-gap: vw(5);
		place-content: center;
		place-items: center;
		position: fixed;
		top: 0;
		right: 0;
		width: vw(72);
		height: vw(60);
		background-color: $primary01;
		z-index: $burgerBtnZIndex;
		&--close {
			position: absolute;
			z-index: 3;
		}
		&__icon {
			width: vw(22);
			&--close {
				width: vw(19);
				height: vw(19);
			}
		}
		&__text {
			color: $white01;
			font-size: vw(12);
			line-height: 1;
		}
	}
}
