@use '../global/import' as *;

@include pc {
	.columns-item {
		$this: &;
		display: flex;
		padding: 20px;
		border: 1px solid $primary01;
		gap: 20px;
		@include hoverOpacity;
		&:hover {
			#{$this}__img {
				scale: $scaleSmall;
			}
		}
		&__picture {
			display: block;
			width: 140px;
			height: 140px;
			overflow: hidden;
		}
		&__img {
			width: 100%;
			height: 100%;
			object-fit: cover;
			transition: $transitionShort;
		}
		&__block {
			flex: 1;
			display: grid;
			row-gap: 18px;
			grid-template-rows: min-content min-content min-content;
		}
		&__title {
			line-height: $lhSmall;
			color: $primary01;
			font-size: 18px;
			font-weight: $fwMedium;
		}
		&__lead {
			line-height: $lhSmall;
			display: -webkit-box;
			-webkit-box-orient: vertical;
			-webkit-line-clamp: 2;
			overflow: hidden;
		}
		&__date {
			line-height: $lhFlat;
			color: $gray04;
			font-size: 12px;
		}
	}
}

@include sp {
	.columns-item {
		$this: &;
		display: flex;
		padding: vw(15);
		border: vw(1) solid $primary01;
		gap: vw(20);
		@include hoverOpacity;
		&:hover {
			#{$this}__img {
				scale: $scaleSmall;
			}
		}
		&__picture {
			display: block;
			width: vw(90);
			height: vw(90);
			overflow: hidden;
		}
		&__img {
			width: 100%;
			height: 100%;
			object-fit: cover;
			transition: $transitionShort;
		}
		&__block {
			flex: 1;
			margin-top: vw(-2);
			display: grid;
			row-gap: vw(5);
		}
		&__title {
			line-height: $lhSmall;
			color: $primary01;
			font-size: vw(18);
			font-weight: $fwMedium;
		}
		&__lead {
			display: none;
		}
		&__date {
			line-height: $lhFlat;
			color: $gray04;
			font-size: vw(12);
		}
	}
}
