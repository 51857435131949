@use '../global/import' as *;

@include pc {
	.list01 {
		&__item {
			@include incListItem01;
		}
	}
}

@include sp {
	.list01 {
		&__item {
			@include incListItem01(sp);
		}
	}
}
