@use '../global/import' as *;

@include pc {
	.list-order02 {
		$this: &;
		counter-reset: number 0;
		display: grid;
		row-gap: 10px;
		margin-top: 30px;
		&__item {
			position: relative;
			padding-left: 1.2em;
			&::before {
				position: absolute;
				top: 0;
				left: 0;
				content: counter(number) ".";
				counter-increment: number;
				font-weight: $fwBold;
			}
		}
		&__title {
			font-weight: $fwBold;
		}
	}
}

@include sp {
	$this: &;
	.list-order02 {
		counter-reset: number 0;
		display: grid;
		row-gap: vw(15);
		margin-top: vw(30);
		&__item {
			position: relative;
			padding-left: 1.2em;
			&::before {
				position: absolute;
				top: 0;
				left: 0;
				content: counter(number) ".";
				counter-increment: number;
				font-weight: $fwBold;
			}
		}
		&__title {
			font-weight: $fwBold;
		}
	}
}
