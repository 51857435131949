@use '../global/import' as *;

@include pc {
	.title01 {
		padding: 50px 0;
		background-image: $linearGradientPrimary01;
		text-align: center;
		&--category {
			position: relative;
			padding: 80px 0;
			background-repeat: no-repeat;
			background-size: cover;
			background-position: top center;
			&::before {
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				background-color: $opacityPrimay01;
				content: "";
			}
		}
		&__text {
			width: 1000px;
			margin: 0 auto;
			position: relative;
			z-index: $title01ZIndex;
			display: block;
			color: $white01;
			font-size: 33px;
			font-weight: 700;
			line-height: $lhSmall;
		}
	}
}

@include sp {
	.title01 {
		padding: vw(20) 0;
		background-image: $linearGradientPrimary01;
		text-align: center;
		&--category {
			position: relative;
			padding: vw(38) 0;
			background-repeat: no-repeat;
			background-size: cover;
			background-position: top center;
			&::before {
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				background-color: $opacityPrimay01;
				content: "";
			}
		}
		&__text {
			display: block;
			position: relative;
			z-index: $title01ZIndex;
			margin: 0 vw(10);
			color: $white01;
			font-size: vw(24);
			font-weight: 700;
			line-height: $lhSmall;
		}
	}
}
