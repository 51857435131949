@use '../settings/color' as *;
@use '../settings/font' as *;
@use '../function/function' as *;

@mixin incList01($media: pc) {
	@if $media == pc {
		display: grid;
		row-gap: 15px;
		margin-top: 20px;
	} @else {
		display: grid;
		row-gap: vw(15);
		margin-top: vw(20);
	}
}

@mixin incListItem01($media: pc) {
	@if $media == pc {
		position: relative;
		padding: 0 0 0 15px;
		&::before {
			position: absolute;
			top: 0.5em;
			left: 0;
			transform: translateY(50%);
			width: 6px;
			height: 6px;
			background: $primary01;
			border-radius: 50%;
			content: "";
		}
	} @else {
		position: relative;
		padding: 0 0 0 vw(15);
		font-size: vw(15);
		&::before {
			position: absolute;
			top: 0.5em;
			left: 0;
			transform: translateY(50%);
			width: vw(6);
			height: vw(6);
			background: $primary01;
			border-radius: 50%;
			content: "";
		}
	}
}
