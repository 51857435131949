@use '../global/import' as *;

@include pc {
	.box01 {
		margin-top: 30px;
		padding: 30px 20px;
		background-color: $beige01;
		&--primary {
			background-color: $primary03;
		}
	}
}

@include sp {
	.box01 {
		margin-top: vw(30);
		padding: vw(30) vw(20);
		background-color: $beige01;
		&--primary {
			background-color: $primary03;
		}
	}
}
