@use '../settings/color' as *;
@use '../settings/font' as *;
@use '../function/function' as *;

@mixin incListOrder01($media: pc) {
	@if $media == pc {
		counter-reset: number 0;
		display: grid;
		row-gap: 15px;
		margin-top: 30px;
	} @else {
		counter-reset: number 0;
		display: grid;
		row-gap: vw(20);
		margin-top: vw(30);
	}
}

@mixin incListOrderItem01($media: pc) {
	@if $media == pc {
		position: relative;
		padding-left: 40px;
		&::before {
			display: grid;
			place-content: center;
			position: absolute;
			top: -0.8em;
			left: 0;
			transform: translateY(50%);
			width: 30px;
			height: 30px;
			border-radius: 50%;
			background: $primary01;
			color: $white01;
			font-size: 20px;
			font-weight: $fwBold;
			counter-increment: number 1;
			content: counter(number);
		}
	} @else {
		position: relative;
		padding-left: vw(40);
		&::before {
			display: grid;
			place-content: center;
			position: absolute;
			top: -0.8em;
			left: 0;
			transform: translateY(50%);
			width: vw(30);
			height: vw(30);
			border-radius: 50%;
			background: $primary01;
			color: $white01;
			font-size: vw(20);
			font-weight: $fwBold;
			counter-increment: number 1;
			content: counter(number);
		}
	}
}
