@use '../global/import' as *;

@include pc {
	.header {
		padding: 15px 0 25px;
		&__inner {
			display: flex;
			justify-content: space-between;
			align-items: flex-end;
		}
		&__content {
			display: flex;
			justify-content: space-between;
			align-items: center;
			width: fit-content;
			gap: 15px;
		}
	}
}

@include sp {
	.header {
		position: relative;
		&__inner {
			position: relative;
			display: flex;
			align-items: center;
			justify-content: space-between;
			height: vw(60);
		}
	}
}
