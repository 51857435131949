// color
$primary01: #b21f31;
$primary02: #e8bbc1;
$primary03: #f7e8ea;
$white01: #fff;
$black01: #333;
$gray01: #fafafa;
$gray02: #dedede;
$gray03: #ddd;
$gray04: #999;
$beige01: #f2ebe1;

// marker
$marker01: #fced00;

// cta
$green01: #007619;

// shadow
$shadowPrimary01: #890e1a;
$shadowGreen01: #054a15;
$shadowBlack01: rgba(51, 51, 51, 0.3);

// opacity color
$opacityPrimay01: rgba(178, 31, 49, 0.3);

// gradient
$linearGradientPrimary01: linear-gradient(90deg, $primary01 1%, #dc394d 50%, #e25667 83%, #e77281 100%);
