@use '../global/import' as *;

@include pc {
	.logo {
		display: inline-block;
		width: 341px;
		&__picture {
			width: 100%;
		}
		&__img {
			width: 100%;
		}
		&--header {
			@include hoverOpacity();
		}
	}
}

@include sp {
	.logo {
		display: inline-block;
		width: vw(256);
		&--header {
			position: absolute;
			top: 50%;
			left: vw(10);
			transform: translate(0, -50%);
			@include hoverOpacity();
		}
		&--header-menu {
			img {
				position: relative;
				top: -1px;
				left: -1px;
			}
		}
		&--footer {
			width: vw(326);
		}
	}
}
