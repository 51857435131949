@use '../global/import' as *;

@include pc {
	.columns-head {
		&__thumb {
			display: block;
			width: 650px;
			height: 400px;
			margin: 30px auto 0;
		}
		&__img {
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
		&__lead {
			margin-top: 30px;
		}
	}
}

@include sp {
	.columns-head {
		&__thumb {
			display: block;
			width: 100%;
			height: vw(218);
			margin-top: vw(12);
		}
		&__img {
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
		&__lead {
			margin-top: vw(10);
		}
	}
}
