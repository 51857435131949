@use '../global/import' as *;

@include pc {
	.footer-nav {
		$this: &;
		display: grid;
		row-gap: 20px;
		padding-left: 60px;
		border-left: 2px solid $white01;
		font-size: 12px;
		&__list {
			display: flex;
			flex-wrap: wrap;
			gap: 5px 15px;
		}
		&__link {
			display: inline-block;
			border-bottom: 1px solid $white01;
			@include hoverOpacity;
			&--blank {
				position: relative;
				padding-right: 3px;
				&:after {
					display: inline-block;
					position: relative;
					top: 2px;
					left: 2px;
					width: 14px;
					height: 14px;
					background: url(#{$imgPath}/icon-blankWhite.svg) no-repeat center / contain;
					content: "";
				}
			}
		}
	}
}

@include sp {
	.footer-nav {
		$this: &;
		border-radius: vw(6);
		background-color: $white01;
		color: $black01;
		&__list {
			&--other {
				#{$this}__item:last-of-type {
					border-bottom: none;
				}
			}
		}
		&__item {
			border-bottom: vw(1) $gray02 solid;
		}
		&__link {
			display: block;
			position: relative;
			padding: vw(10) vw(5) vw(10) vw(15);
			@include hoverOpacity;
			&--internal {
				::after {
					position: absolute;
					top: 50%;
					right: vw(10);
					transform: translate(0, -50%);
					width: vw(19);
					height: vw(9);
					background: url(#{$imgPath}/arrow-linkBlack.webp) no-repeat center / contain;
					content: "";
				}
			}
			&--blank {
				#{$this}__text {
					position: relative;
					&:after {
						display: inline-block;
						position: relative;
						top: vw(1);
						left: vw(5);
						width: vw(14);
						height: vw(14);
						background: url(#{$imgPath}/icon-blank.svg) no-repeat center / contain;
						content: "";
					}
				}
			}
		}
	}
}
