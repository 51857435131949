@use '../global/import' as *;

@include pc {
	.footer-info {
		font-size: 12px;
		&__group-list {
			margin-top: 10px;
		}
		&__group-item {
			font-size: 12px;
		}
		&__address {
			margin-top: 12px;
		}
		&__privacy-list {
			display: flex;
			margin-top: 15px;
			width: fit-content;
		}
		&__privacy-item {
			&:first-of-type {
				position: relative;
				padding: 0 25px 0 0;
				&::before {
					position: absolute;
					top: 50%;
					right: 0%;
					transform: translateY(-50%);
					width: 1px;
					height: 15px;
					background-color: $white01;
					content: "";
				}
			}
			&:last-of-type {
				padding: 0 0 0 25px;
			}
		}
		&__privacy-link {
			display: inline-block;
			border-bottom: 1px solid $white01;
			@include hoverOpacity;
		}
		&__copy {
			margin-top: 20px;
			font-size: 12px;
		}
	}
}

@include sp {
	.footer-info {
		font-size: vw(12);
		&__group-list {
			margin-top: vw(10);
		}
		&__address {
			margin-top: vw(15);
		}
		&__privacy-list {
			display: flex;
			gap: vw(20);
			margin-top: vw(20);
		}
		&__privacy-link {
			display: inline-block;
			border-bottom: vw(1) solid $white01;
			@include hoverOpacity;
		}
		&__copy {
			margin-top: vw(15);
			text-align: center;
		}
	}
}
