@use '../global/import' as *;

@use '../global/import' as *;

@include pc {
	.cta-tel {
		$this: &;
		padding-top: 4px;
		&--lower {
			padding-top: 6px;
		}
		&--header {
			padding-top: 3px;
		}
		&--center {
			display: block;
			text-align: center;
		}
	}
}

@include sp {
	.cta-tel {
		display: none;
	}
}
