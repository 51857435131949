@use '../global/import' as *;

// font-face
@font-face {
	src: url(#{$fontPath}/NotoSansJP-Regular.otf) format('opentype');
	font-family: 'NotoSansJP';
	font-weight: $fwNormal;
	font-display: swap;
}
@font-face {
	src: url(#{$fontPath}/NotoSansJP-Medium.otf) format('opentype');
	font-family: 'NotoSansJP';
	font-weight: $fwMedium;
	font-display: swap;
}
@font-face {
	src: url(#{$fontPath}/NotoSansJP-Bold.otf) format('opentype');
	font-family: 'NotoSansJP';
	font-weight: $fwBold;
	font-display: swap;
}

// pc・SP共通
body {
	color: $black01;
	font-family: 'NotoSansJP', sans-serif;
	word-break: break-all;
}
body.js-fixed {
	width: 100%;
	height: 100%;
	position: fixed;
}
img {
	max-width: 100%;
	height: auto;
}
picture {
	display: inline-block;
}
p,li,dt,dd {
	line-height: $lhNormal;
}
// styleguide用
.fn-article div.fn-preview {
	font-family: 'NotoSansJP', sans-serif;
}

// pc
@include pc {
	body {
		font-size: 16px;
	}
	// styleguide用
	.fn-article div.fn-preview {
		font-size: 16px;
	}
}

// sp
@include sp {
	body {
		font-size: vw(15);
	}
	// styleguide用
	.fn-article div.fn-preview {
		font-size: vw(15);
	}
}

