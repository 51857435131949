@use '../global/import' as *;

@include pc {
	.link-box {
		display: block;
		margin-top: 30px;
		padding: 20px;
		background-color: $gray01;
		@include hoverOpacity;
		&__text {
			position: relative;
			border-bottom: 1px solid $primary01;
			color: $primary01;
			&::after {
				position: absolute;
				top: 50%;
				right: -10px;
				transform: translate(100%, -50%);
				width: 20px;
				height: 9px;
				background: url(/wp-content/themes/service/assets/img/arrow-navRed.webp) no-repeat center/contain;
				content: "";
			}
		}
	}
}

@include sp {
	.link-box {
		display: block;
		margin-top: vw(30);
		padding: vw(20);
		background-color: $gray01;
		@include hoverOpacity;
		&__text {
			position: relative;
			border-bottom: vw(1) solid $primary01;
			color: $primary01;
			&::after {
				position: absolute;
				top: 50%;
				right: vw(-10);
				transform: translate(100%, -50%);
				width: vw(17);
				height: vw(10);
				background: url(/wp-content/themes/service/assets/img/arrow-navRed.webp) no-repeat center/contain;
				content: "";
			}
		}
	}
}
