@use '../global/import' as *;

@include pc {
	.text {
		margin-top: 30px;
	}
}

@include sp {
	.text {
		margin-top: vw(30);
	}
}
