@use '../global/import' as *;

@include pc {
	.btn-tel {
		display: none;
	}
}

@include sp {
	.btn-tel {
		$this: &;
		display: grid;
		row-gap: vw(10);
		place-content: center;
		place-items: center;
		width: min(vw(313), 100%);
		height: vw(70);
		margin: 0 auto;
		box-shadow: 0 vw(4) $shadowPrimary01;
		border-radius: vw(5);
		background-color: $primary01;
		color: $white01;
		font-weight: $fwBold;
		@include hoverOpacity;
		&:hover {
			transform: translateY(vw(4));
			box-shadow: none;
		}
		&__title {
			position: relative;
			width: fit-content;
			margin: 0 auto;
			padding: vw(2) 0 0 vw(48);
			line-height: $lhFlat;
			font-size: vw(23);
			&::before {
				position: absolute;
				top: 0;
				left: 0;
				width: vw(38);
				height: vw(30);
				background: url(#{$imgPath}/icon-telWhite.svg) no-repeat center / contain;
				content: "";
			}
		}
		&__text {
			line-height: $lhFlat;
			font-size: vw(12);
			text-align: center;
		}
		&--sm {
			width: vw(170);
			height: vw(50);
			border: vw(2) $primary01 solid;;
			border-bottom: vw(2) $shadowPrimary01 solid;
			box-shadow: 0 vw(1.5) $shadowPrimary01;
			row-gap: vw(7);
			&:hover {
				transform: translateY(vw(1.5));
				box-shadow: none;
				border-bottom: vw(2) $primary01 solid;
			}
			#{$this}__title {
				padding-left: vw(28);
				font-size: vw(15);
				&::before {
					width: vw(24);
					height: vw(20);
				}
			}
			#{$this}__text {
				font-size: vw(10);
			}
		}
	}
}
