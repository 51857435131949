@use './global/import' as *;

@include pc {
	.wp-block {
		& > *:not(#_):first-child {
			margin-top: 0;
		}
		/*
		********** 通常テキスト **********
		*/
		p {
			margin-top: 20px;
		}
		/*
		********** リンク **********
		*/
		a {
			@include incLinkText;
			&[target="_blank"]{
				@include incLinkTextBlank();
			}
		}
		/*
		********** 見出し **********
		*/
		// h2
		h2 {
			&.wp-block-heading {
				@include incTitle03;
			}
		}
		// h3
		h3 {
			&.wp-block-heading {
				@include incTitle04;
			}
		}
		// h4
		h4 {
			&.wp-block-heading {
				@include incTitle05;
			}
		}
		/*
		********** リスト **********
		*/
		ul {
			@include incList01();
			li {
				@include incListItem01();
				* {
					line-height: $lhFlat;
				}
			}
		}
		ol {
			@include incListOrder01;
			& > li {
				@include incListOrderItem01;
				* {
					line-height: $lhFlat;
				}
			}
		}
		/*
		********** テーブル **********
		*/
		.wp-block-table {
			margin-top: 20px;
			overflow-x: auto;
			table {
				width: 100%;
				border: $gray02 2px solid;
				&.has-fixed-layout {
					table-layout: fixed;
				}
			}
			thead,tfoot {
				th,td {
					vertical-align: middle;
					padding: 15px 25px;
					border: $gray02 2px solid;
					background-color: $gray01;
					line-height: $lhNormal;
				}
			}
			tbody {
				td {
					vertical-align: middle;
					padding: 15px 25px;
					border: $gray02 2px solid;
					line-height: $lhNormal;
				}
			}
			.wp-element-caption {
				margin-top: 15px;
				font-size: 11px;
				line-height: $lhNormal;
			}
		}
		/*
		********** 画像 **********
		*/
		.wp-block-image {
			max-width: 100%;
			margin-top: 20px;
			img {
				object-fit: cover;
			}
		}
		/*
		********** カラム **********
		*/
		.wp-block-columns {
			display: flex;
			gap: 40px;
			margin-top: 30px;
			.wp-block-column {
				flex: 1;
				& > * {
					margin-top: 20px;
				}
				& > *:first-of-type {
					margin-top: 0;
				}
			}
		}
		/*
		********** 装飾 **********
		*/
		// マーカー
		.wp-marker {
			background: linear-gradient(transparent 70%, $marker01 70%);
		}
		// カラー
		.wp-color-primary {
			color: $primary01;
		}
		// 大文字
		.wp-font-large {
			font-size: 20px;
		}
		// 小文字
		.wp-font-small {
			font-size: 11px;
		}
		// 中央揃え
		.has-text-align-center, .aligncenter {
			text-align: center;
		}
		// 左揃え
		.has-text-align-left, .alignleft {
			text-align: left;
		}
		// 右揃え
		.has-text-align-right, .alignright {
			text-align: right;
		}
	}
}

@include sp {
	.wp-block {
		& > *:not(#_):first-child {
			margin-top: 0;
		}
		/*
		********** 通常テキスト **********
		*/
		p {
			margin-top: vw(20);
		}
		/*
		********** リンク **********
		*/
		a {
			@include incLinkText(sp);
			&[target="_blank"]{
				@include incLinkTextBlank();
			}
		}
		/*
		********** 見出し **********
		*/
		// h2
		h2 {
			&.wp-block-heading {
				@include incTitle03(sp);
			}
		}
		// h3
		h3 {
			&.wp-block-heading {
				@include incTitle04(sp);
			}
		}
		// h4
		h4 {
			&.wp-block-heading {
				@include incTitle05(sp);
			}
		}
		/*
		********** リスト **********
		*/
		ul {
			@include incList01(sp);
			li {
				@include incListItem01(sp);
				* {
					line-height: $lhFlat;
				}
			}
		}
		ol {
			@include incListOrder01(sp);
			& > li {
				@include incListOrderItem01(sp);
				* {
					line-height: $lhFlat;
				}
			}
		}
		/*
		********** テーブル **********
		*/
		.wp-block-table {
			margin-top: vw(20);
			overflow-x: auto;
			table {
				width: 100%;
				border: $gray02 vw(1) solid;
				&.has-fixed-layout {
					table-layout: fixed;
				}
			}
			thead,tfoot {
				th,td {
					vertical-align: middle;
					padding: vw(10);
					border: vw(1) solid $gray02;
					background-color: $gray01;
					line-height: $lhNormal;
					text-align: center;
				}
			}
			tbody {
				td {
					vertical-align: middle;
					padding: vw(10);
					border: vw(1) solid $gray02;
					line-height: $lhNormal;
				}
			}
			.wp-element-caption {
				margin-top: vw(10);
				font-size: vw(11);
				line-height: $lhNormal;
			}
		}
		/*
		********** 画像 **********
		*/
		.wp-block-image {
			max-width: 100%;
			margin-top: vw(20);
			img {
				object-fit: cover;
			}
		}
		/*
		********** カラム **********
		*/
		.wp-block-columns {
			display: flex;
			flex-direction: column;
			margin-top: vw(30);
			.wp-block-column {
				flex: 1;
				& > * {
					margin-top: vw(20);
				}
				& > .wp-block-image:first-of-type {
					margin-top: 0;
				}
			}
		}
		/*
		********** 装飾 **********
		*/
		// マーカー
		.wp-marker {
			background: linear-gradient(transparent 70%, $marker01 70%);
		}
		// カラー
		.wp-color-primary {
			color: $primary01;
		}
		// 大文字
		.wp-font-large {
			font-size: vw(17);
		}
		// 小文字
		.wp-font-small {
			font-size: vw(11);
		}
		// 中央揃え
		.has-text-align-center, .aligncenter {
			text-align: center;
		}
		// 左揃え
		.has-text-align-left, .alignleft {
			text-align: left;
		}
		// 右揃え
		.has-text-align-right, .alignright {
			text-align: right;
		}
	}
}
