@use '../global/import' as *;

@include pc {
	.btn-link03 {
		display: grid;
		place-content: center;
		position: relative;
		width: 500px;
		height: 70px;
		box-shadow: 0 4px $shadowPrimary01;
		border-radius: 5px;
		background-color: $primary01;
		color: $white01;
		font-size: 17px;
		font-weight: $fwMedium;
		text-align: center;
		@include hoverOpacity;
		&:hover {
			box-shadow: none;
			transform: translateY(4px);
		}
		&--contact {
			&::before {
				position: absolute;
				top: 50%;
				left: 30px;
				transform: translateY(-50%);
				width: 55px;
				height: 42px;
				background-image: url(#{$imgPath}/icon-webWhite.svg);
				background-repeat: no-repeat;
				background-position: top center;
				background-size: contain;
				content: "";
			}
		}
		&--column {
			&::before {
				position: absolute;
				top: 50%;
				left: 30px;
				transform: translateY(-50%);
				width: 53px;
				height: 55px;
				background-image: url(#{$imgPath}/icon-column.svg);
				background-repeat: no-repeat;
				background-position: top center;
				background-size: contain;
				content: "";
			}
		}
		&--internet {
			&::before {
				position: absolute;
				top: 50%;
				left: 30px;
				transform: translateY(-50%);
				width: 48px;
				height: 48px;
				background-image: url(#{$imgPath}/icon-internet.webp);
				background-repeat: no-repeat;
				background-position: top center;
				background-size: contain;
				content: "";
			}
		}
		&--saiken {
			&::before {
				position: absolute;
				top: 50%;
				left: 30px;
				transform: translateY(-50%);
				width: 48px;
				height: 49px;
				background-image: url(#{$imgPath}/icon-saiken.webp);
				background-repeat: no-repeat;
				background-position: top center;
				background-size: contain;
				content: "";
			}
		}
		&--realestate {
			&::before {
				position: absolute;
				top: 50%;
				left: 30px;
				transform: translateY(-50%);
				width: 48px;
				height: 49px;
				background-image: url(#{$imgPath}/icon-realestate.webp);
				background-repeat: no-repeat;
				background-position: top center;
				background-size: contain;
				content: "";
			}
		}
		&--consumer {
			&::before {
				position: absolute;
				top: 50%;
				left: 30px;
				transform: translateY(-50%);
				width: 48px;
				height: 48px;
				background-image: url(#{$imgPath}/icon-consumer.webp);
				background-repeat: no-repeat;
				background-position: top center;
				background-size: contain;
				content: "";
			}
		}
		&::after {
			position: absolute;
			top: 50%;
			right: 10px;
			transform: translate(0, -50%);
			width: 33px;
			height: 10px;
			background: url(#{$imgPath}/arrow-next.webp) no-repeat center / contain;
			content: '';
		}
	}
}

@include sp {
	.btn-link03 {
		display: grid;
		place-content: center;
		position: relative;
		width: 100%;
		height: vw(70);
		box-shadow: 0 vw(4) $shadowPrimary01;
		border-radius: vw(5);
		background-color: $primary01;
		color: $white01;
		font-weight: $fwMedium;
		text-align: center;
		@include hoverOpacity;
		&:hover {
			box-shadow: none;
			transform: translateY(vw(4))
		}
		&--contact {
			&::before {
				position: absolute;
				top: 50%;
				left: vw(20);
				transform: translateY(-50%);
				width: vw(53);
				height: vw(41);
				background-image: url(#{$imgPath}/icon-webWhite.svg);
				background-repeat: no-repeat;
				background-position: top center;
				background-size: contain;
				content: "";
			}
		}
		&--column {
			&::before {
				position: absolute;
				top: 50%;
				left: vw(20);
				transform: translateY(-50%);
				width: vw(53);
				height: vw(55);
				background-image: url(#{$imgPath}/icon-column.svg);
				background-repeat: no-repeat;
				background-position: top center;
				background-size: contain;
				content: "";
			}
		}
		&--internet {
			&::before {
				position: absolute;
				top: 50%;
				left: vw(20);
				transform: translateY(-50%);
				width: vw(48);
				height: vw(48);
				background-image: url(#{$imgPath}/icon-internet.webp);
				background-repeat: no-repeat;
				background-position: top center;
				background-size: contain;
				content: "";
			}
		}
		&--saiken {
			&::before {
				position: absolute;
				top: 50%;
				left: vw(20);
				transform: translateY(-50%);
				width: vw(48);
				height: vw(49);
				background-image: url(#{$imgPath}/icon-saiken.webp);
				background-repeat: no-repeat;
				background-position: top center;
				background-size: contain;
				content: "";
			}
		}
		&--realestate {
			&::before {
				position: absolute;
				top: 50%;
				left: vw(20);
				transform: translateY(-50%);
				width: vw(48);
				height: vw(49);
				background-image: url(#{$imgPath}/icon-realestate.webp);
				background-repeat: no-repeat;
				background-position: top center;
				background-size: contain;
				content: "";
			}
		}
		&--consumer {
			&::before {
				position: absolute;
				top: 50%;
				left: vw(20);
				transform: translateY(-50%);
				width: vw(48);
				height: vw(48);
				background-image: url(#{$imgPath}/icon-consumer.webp);
				background-repeat: no-repeat;
				background-position: top center;
				background-size: contain;
				content: "";
			}
		}
		&::after {
			position: absolute;
			top: 50%;
			right: vw(10);
			transform: translate(0, -50%);
			width: vw(33);
			height: vw(10);
			background: url(#{$imgPath}/arrow-next.webp) no-repeat center / contain;
			content: '';
		}
	}
}
