@use '../global/import' as *;

@include pc {
	.title03 {
		@include incTitle03;
	}
}

@include sp {
	.title03 {
		@include incTitle03(sp);
	}
}
