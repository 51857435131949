@use '../global/import' as *;

@include pc {
	.floating {
		&__block {
			display: grid;
			place-content: center;
			position: fixed;
			bottom: 0;
			right: 0;
			width: 100%;
			height: 100px;
			background-color: $white01;
			box-shadow: 0 0 15px 0 $shadowBlack01;
		}
		&__inner {
			display: flex;
			align-items: center;
			justify-content: space-between;
		}
	}
}

@include sp {
	.floating {
		&__block {
			display: grid;
			place-content: center;
			position: fixed;
			bottom: 0;
			right: 0;
			width: 100%;
			height: vw(70);
			background-color: $white01;
			box-shadow: 0 0 vw(15) 0 $shadowBlack01;
		}
		&__inner {
			display: flex;
			align-items: center;
			justify-content: center;
			gap: vw(10);
			margin: 0;
		}
	}
}
