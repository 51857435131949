@use '../global/import' as *;

.w-auto:not(#_) {
	width: auto;
}
.w-fit:not(#_) {
	width: fit-content;
}
.w-100p:not(#_) {
	width: 100%;
}

@include pc {
	.w-auto-pc:not(#_) {
		width: auto;
	}
	.w-fit-pc:not(#_) {
		width: fit-content;
	}
	.w-100p-pc:not(#_) {
		width: 100%;
	}
}

@include sp {
	.w-auto-sp:not(#_) {
		width: auto;
	}
	.w-fit-sp:not(#_) {
		width: fit-content;
	}
	.w-100p-sp:not(#_) {
		width: 100%;
	}
}
