@use '../global/import' as *;

@include pc {
	.notes {
		$this: &;
		display: grid;
		row-gap: 4px;
		margin-top: 15px;
		&--prefix {
			#{$this}__item {
				position: relative;
				display: flex;
				column-gap: 2px;
			}
		}
		&__item {
			font-size: 11px;
			line-height: $lhSmall;
		}
	}
}

@include sp {
	.notes {
		$this: &;
		display: grid;
		row-gap: vw(5);
		margin-top: vw(10);
		&--prefix {
			#{$this}__item {
				position: relative;
				display: flex;
				column-gap: vw(2);
			}
		}
		&__item {
			font-size: vw(12);
			line-height: $lhSmall;
		}
	}
}
