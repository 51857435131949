@use '../global/import' as *;

@include pc {
	.wrapper {
		display: grid;
		grid-template-rows: auto auto 1fr auto;
		min-height: 100vh;
		overflow-x: hidden;
		&--row-3 {
			grid-template-rows: auto 1fr auto;
		}
	}
}

@include sp {
	.wrapper {
		overflow-x: hidden;
	}
}
