@use '../global/import' as *;

@include pc {
	.main {
		padding: 0 0 100px;
	}
}

@include sp {
	.main {
		padding: 0 0 vw(70);
	}
}
