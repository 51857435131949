@use '../global/import' as *;

@include pc {
	.link-text {
		@include incLinkText;
		&--blank {
			@include incLinkTextBlank();
		}
	}
}

@include sp {
	.link-text {
		@include incLinkText(sp);
		&--blank {
			@include incLinkTextBlank(sp);
		}
	}
}
