@use '../global/import' as *;

@include pc {
	.title02 {
		position: relative;
		width: fit-content;
		margin: 0 auto;
		font-size: 30px;
		font-weight: $fwBold;
		text-align: center;
		& + * {
			margin-top: 60px;
		}
		&::before {
			position: absolute;
			left: 50%;
			bottom: -20px;
			transform: translate(-50%, 0);
			width: 50px;
			height: 4px;
			background-color: $primary01;
			content: '';
		}
		&--sm {
			font-size: 28px;
			&::before {
				bottom: -22px;
			}
		}
	}
}

@include sp {
	.title02 {
		position: relative;
		width: fit-content;
		margin: 0 auto;
		font-size: vw(28);
		font-weight: $fwBold;
		text-align: center;
		& + * {
			margin-top: vw(45);
		}
		&::before {
			position: absolute;
			left: 50%;
			bottom: vw(-15);
			transform: translate(-50%, 0);
			width: vw(50);
			height: vw(4);
			background-color: $primary01;
			content: '';
		}
		&--sm {
			font-size: vw(24);
			&::before {
				bottom: vw(-19);
			}
		}
	}
}
