@use '../global/import' as *;

.p0:not(#_) {
	padding: 0;
}
.pt0:not(#_) {
	padding-top: 0;
}
.pb0:not(#_) {
	padding-bottom: 0;
}
.pl0:not(#_) {
	padding-left: 0;
}
.pr0:not(#_) {
	padding-right: 0;
}

@include pc {
	@for $i from 0 through 20 {
		.pt#{$i * 5}-pc:not(#_) {
			padding-top: 5px * $i;
		}
		.pb#{$i * 5}-pc:not(#_) {
			padding-bottom: 5px * $i;
		}
		.pl#{$i * 5}-pc:not(#_) {
			padding-left: 5px * $i;
		}
		.pr#{$i * 5}-pc:not(#_) {
			padding-right: 5px * $i;
		}
	}
	.p0-pc:not(#_) {
		padding: 0;
	}
	.pt0-pc:not(#_) {
		padding-top: 0;
	}
	.pb0-pc:not(#_) {
		padding-bottom: 0;
	}
	.pl0-pc:not(#_) {
		padding-left: 0;
	}
	.pr0-pc:not(#_) {
		padding-right: 0;
	}
}

@include sp {
	@for $i from 0 through 20 {
		.pt#{$i * 5}-sp:not(#_) {
			padding-top: vw(5) * $i;
		}
		.pb#{$i * 5}-sp:not(#_) {
			padding-bottom: vw(5) * $i;
		}
		.pl#{$i * 5}-sp:not(#_) {
			padding-left: vw(5) * $i;
		}
		.pr#{$i * 5}-sp:not(#_) {
			padding-right: vw(5) * $i;
		}
	}
	.p0-sp:not(#_) {
		padding: 0;
	}
	.pt0-sp:not(#_) {
		padding-top: 0;
	}
	.pb0-sp:not(#_) {
		padding-bottom: 0;
	}
	.pl0-sp:not(#_) {
		padding-left: 0;
	}
	.pr0-sp:not(#_) {
		padding-right: 0;
	}
}
