@use '../global/import' as *;

@include pc {
	.breadcrumb {
		display: flex;
		column-gap: 30px;
		margin-top: 30px;
		padding: 0 0 10px;
		overflow-x: auto;
		white-space: nowrap;
		// & + * {
		// 	margin-top: 30px;
		// }
		&__item {
			position: relative;
			&:not(:last-of-type) {
				&::after {
					position: absolute;
					top: 14px;
					right: -17px;
					width: 8px;
					height: 8px;
					border-top: 2px solid $primary01;
					border-right: 2px solid $primary01;
					transform: translateY(-50%) rotate(45deg);
					content: "";
				}
			}
		}
		&__link {
			border-bottom: 1px solid $black01;
			@include hoverOpacity;
		}
	}
}

@include sp {
	.breadcrumb {
		display: flex;
		column-gap: vw(30);
		margin-top: vw(15);
		padding: 0 0 vw(10);
		overflow-x: auto;
		white-space: nowrap;
		&__item {
			position: relative;
			font-size: vw(12);
			&:not(:last-of-type) {
				&::after {
					position: absolute;
					top: vw(12);
					right: vw(-19);
					width: vw(8);
					height: vw(8);
					border-top: vw(2) solid $primary01;
					border-right: vw(2) solid $primary01;
					transform: translateY(-50%) rotate(45deg);
					content: "";
				}
			}
		}
		&__link {
			border-bottom: vw(1) solid $black01;
			@include hoverOpacity;
		}
	}
}
